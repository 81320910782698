import { useSpring, animated } from 'react-spring'
import styles from './HeadingHome.css'

export function HeadingHome({ titlePartOne, titlePartTwo, layoutClassName }) {
  const springPartOne = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 }
  })

  const springPartTwo = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 300
  })
  return (
    <div className={cx(styles.component, layoutClassName)}>
      <h1 className={styles.heading}>
        <animated.span style={springPartOne} className={styles.headingPartOne}>{titlePartOne}</animated.span>
        <animated.span style={springPartTwo} className={styles.headingPartTwo}>
          <span data-style-context='blue' className={styles.headingAmpersand}>&amp; </span>{titlePartTwo}
        </animated.span>
      </h1>
    </div>
  )
}
