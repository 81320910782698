import { HeadingHome } from '/features/buildingBlocks/HeadingHome'
import { BackgroundVideo } from '/features/buildingBlocks/BackgroundVideo'

import styles from './HomeHero.css'

import poster from '/images/videoPosterHome.jpg'

export function HomeHero({ titlePartOne, titlePartTwo, videoSources }) {
  return (
    <div className={styles.component}>
      {videoSources && (
        <BackgroundVideo
          sources={videoSources}
          layoutClassName={styles.videoLayout}
          {...{ poster }}
        />
      )}
      {titlePartOne && titlePartTwo && (
        <HeadingHome
          layoutClassName={styles.headingHomeLayout}
          {...{ titlePartOne, titlePartTwo }}
        />
      )}
    </div>
  )
}
